import { Link } from "gatsby"
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import PlayArrow from "@material-ui/icons/PlayArrow"
import Hidden from "@material-ui/core/Hidden"
import Apple from "@material-ui/icons/Apple"
import Logo from "../Logo/Horizontal"
import React from "react"

const Header = ({ }) => (
  <Box display="flex" alignItems="center" py={1} px={2}>
    <Link
      style={{ textDecoration: "none", color: "inherit", display: "inherit" }}
      to="/"
    >
      <Logo />
    </Link>
    <Box flexGrow={1} />
    <Box ml={1}>
      <Button to="/" component={Link}>
        Home
      </Button>
    </Box>
    <Box ml={1}>
      <Button to="/about" component={Link}>
        About
      </Button>
    </Box>
    <Box ml={1}>
      <Button to="/episodes" component={Link}>
        Episodes
      </Button>
    </Box>
  </Box>
)

export const AppButtons = () => (
  <>
  <Box ml={1}>
      <Button
        variant="contained"
        color="primary"
        component="a"
        href="https://apps.apple.com/app/id1450443351"
        target="_blank"
      >
        <Box display="flex" alignItems="center">
          <Apple />{" "}
          <Hidden smDown>
            <Box ml={1}>App Store</Box>
          </Hidden>
        </Box>
      </Button>
    </Box>
    <Box ml={1}>
      <Button
        variant="contained"
        color="secondary"
        component="a"
        href="https://play.google.com/store/apps/details?id=network.xyo.coin"
        target="_blank"
      >
        <Box display="flex" alignItems="center">
          <PlayArrow />{" "}
          <Hidden smDown>
            <Box ml={1}>Google Play</Box>
          </Hidden>
        </Box>
      </Button>
    </Box>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
